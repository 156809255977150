<template>
  <v-container id="login-page" fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <h2 class="mb-5 text-center">Project Title</h2>
        <v-card class="mb-5 elevation-6">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login form</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-5 pb-0">
            <v-form>
              <v-text-field
                prepend-icon="mdi-account"
                name="login"
                label="Login"
                type="text"
              ></v-text-field>
              <v-text-field
                id="mdi-password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn large color="primary" to="/">Login</v-btn>
          </v-card-actions>
        </v-card>
        <v-img
          src="@/assets/logo-template.png"
          height="125"
        ></v-img>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  props: {
    source: String
  }
}
</script>

<style>
</style>
